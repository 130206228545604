@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "SceneProRg";

  /*Can be any text*/

  src: local("SceneProRg"),
    url("./fonts/SceneProRg.OTF") format("truetype");
}

@font-face {
  font-family: "SceneProBdIt";

  /*Can be any text*/

  src: local("SceneProBdIt"),
    url("./fonts/SceneProBdIt.OTF") format("truetype");
}

@font-face {
  font-family: "SceneProBold";

  /*Can be any text*/

  src: local("SceneProBold"),
    url("./fonts/SceneProBold.OTF") format("truetype");
}

@font-face {
  font-family: "SceneProUltBlk";

  /*Can be any text*/

  src: local("SceneProUltBlk"),
    url("./fonts/SceneProUltBlk.OTF") format("truetype");
}

@font-face {
  font-family: "SceneProUltBlkIt";

  /*Can be any text*/

  src: local("SceneProUltBlkIt"),
    url("./fonts/SceneProUltBlkIt.OTF") format("truetype");
}

@font-face {
  font-family: "SceneProMedIt";

  /*Can be any text*/

  src: local("SceneProMedIt"),
    url("./fonts/SceneProMedIt.OTF") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100%;
  background-color: #000;
  font-family: 'SceneProRg', sans-serif;
  color: #ababab;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* SLIDER */

.wrapper{
  position: relative;
  height: 500px;
  width: 750px;
  overflow: hidden;
  background: #fff;
  /* border: 7px solid #fff; */
  box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
}

.wrapper .images{
  height: 100%;
  width: 100%;
  display: flex;
}

.wrapper .images .img-1{
  height: 100%;
  width: 100%;
  background: url("http://localhost:3000/static/media/tesla-steering-wheel.311b452ca10a0d656260.jpg") no-repeat;
  /* background: url("images/car.jpg") no-repeat; */
}

.wrapper .images .img-2{
  position: absolute;
  height: 100%;
  width: 50%;
  /* filter: blur(5px); */
  background: url("http://localhost:3000/static/media/tesla-front.6fa477eb7697bb5eeaf2.jpg") no-repeat;
  /* background: url("images/car.png") no-repeat; */
}

.wrapper .slider{
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.wrapper .slider input{
  width: 100%;
  outline: none;
  background: none;
  -webkit-appearance: none;
}

.slider input::-webkit-slider-thumb{
  height: 486px;
  width: 3px;
  background: none;
  -webkit-appearance: none;
  cursor: col-resize;
}

.slider .drag-line{
  width: 3px;
  height: 486px;
  position: absolute;
  left: 49.85%;
  pointer-events: none;
}

.slider .drag-line::before,
.slider .drag-line::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 222px;
  background: #fff;
}

.slider .drag-line::before{
  top: 0;
}

.slider .drag-line::after{
  bottom: 0;
}

.slider .drag-line span{
  height: 42px;
  width: 42px;
  border: 3px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider .drag-line span::before,
.slider .drag-line span::after{
  position: absolute;
  content: "";
  top: 50%;
  border: 10px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.slider .drag-line span::before{
  left: 40%;
  border-left-color: #fff;
}

.slider .drag-line span::after{
  left: 60%;
  border-top-color: #fff;
}

/* DROPDOWN MENU */

@media only screen and (max-width: 768px) {
  ul {
    list-style: none;
    /* background: #000; */
  }

  ul li {
    list-style: none;
    color: #fff;
    font-size: 1.5rem;
    padding: 1rem;
  }

  ul li a {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
  }

  ul li ul.dropdown li {
    display: block;
    color: '#FFF';
  }

  ul li ul.dropdown {
    width: 300px;
    font-size: 16px;
    background: #000;
    position: absolute;
    z-index: 999;
    display: none;
    box-shadow: 4px -19px 35px rgb(0 0 0 / 28%), 11px 11px 30px -10px rgb(0 0 0 / 78%);
  }

  ul li a:hover {
  }

  ul li:hover ul.dropdown {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  ul {
    list-style: none;
    /* background: #000; */
  }

  ul li {
    display: inline-block;
    position: relative;
  }

  ul li a {
    /* display: block;
    padding: 20px 25px;
    color: #FFF;
    text-decoration: none;
    font-size: 16px; */
  }

  ul li ul.dropdown li {
    display: block;
    color: '#FFF';
  }

  ul li ul.dropdown {
    width: 300px;
    font-size: 16px;
    background: #000;
    position: absolute;
    z-index: 999;
    display: none;
    box-shadow: 4px -19px 35px rgb(0 0 0 / 28%), 11px 11px 30px -10px rgb(0 0 0 / 78%);
  }

  ul li a:hover {
  }

  ul li:hover ul.dropdown {
    display: block;
  }
}

/* MENU STYLES */

.menu-wrap {
  display: none;
}

@media only screen and (max-width: 768px) {
  .menu-wrap {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin-top: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger Line */

.menu-wrap .hamburger > div {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */

.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */

.menu-wrap .hamburger > div::after {
  top: 10px;
}

/* Toggler Animation */

.menu-wrap .toggler:checked + .hamburger > div {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Turns Lines Into X */

.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */

.menu-wrap .toggler:checked:hover + .hamburger > div {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* Show Menu */

.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-duration: var(--menu-speed);
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition:  opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu > div {
  background-color: #000;
  /* backdrop-filter: blur(10px); */
  font-family: Outfit;
  width: 100%;
  height: 100%;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

.logo {
  width: 100%;
  height: 100%;
  max-height: 100%;
  /* original: 160px */
  max-width: 185px;
  min-height: 100%;
  /* original: 160px */
  min-width: 139px;
  margin-right: auto;
  cursor: pointer;
}

.icon {
  transition: all 300ms ease 0s;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
}

.icon:hover {
  transition: all 300ms ease 0s;
  opacity: .8;
}

.red {
  color: #e80200;
}

.price-gradient {
  background: linear-gradient(180deg, #FFFFFF 12.67%, rgba(255, 255, 255, 0) 75.33%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.1;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/Tesla-modelY-1920px-1280-2880w.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .hero-container {
    position: relative;
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/Tesla-modelY-1920px-1280-2880w.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .hero-container > p {
    font-size: 20px;
  }
}

.gallery-img {
  height: 600px;
}

@media screen and (max-width: 768px) {
  .gallery-img {
    height: 300px;
  }
}

.ceramic-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.ceramic-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.ceramic-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.ceramic-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .ceramic-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .ceramic-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .ceramic-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .ceramic-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .ceramic-hero-container > p {
    font-size: 20px;
  }
}

.contact-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.contact-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-9799992-2880w.jpeg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.contact-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.contact-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .contact-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .contact-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-9799992-2880w.jpeg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .contact-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .contact-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .contact-hero-container > p {
    font-size: 20px;
  }
}

.paint-correction-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.paint-correction-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/Blue+Corvette+paint+correction-2880w.JPG') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.paint-correction-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.paint-correction-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .paint-correction-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .paint-correction-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/Blue+Corvette+paint+correction-2880w.JPG') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .paint-correction-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .paint-correction-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .paint-correction-hero-container > p {
    font-size: 20px;
  }
}

.detailing-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.detailing-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/mustang+interior+detail-2880w.JPG') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.detailing-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.detailing-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .detailing-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .detailing-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/mustang+interior+detail-2880w.JPG') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .detailing-hero-container > p {
    font-size: 20px;
  }
}

.full-detailing-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.full-detailing-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.full-detailing-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.full-detailing-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .full-detailing-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .full-detailing-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .full-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .full-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .full-detailing-hero-container > p {
    font-size: 20px;
  }
}

.exterior-detailing-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.exterior-detailing-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.exterior-detailing-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.exterior-detailing-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .exterior-detailing-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .exterior-detailing-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/202307_ENDLESSDETAILING_150DPI-193-Wondering+More-2880w.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .exterior-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .exterior-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .exterior-detailing-hero-container > p {
    font-size: 20px;
  }
}

.interior-detailing-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.interior-detailing-hero-container::before {
  content: "";
  background: url('http://localhost:3000/static/media/steam-clean.d11071c547b62a995e65.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.interior-detailing-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.interior-detailing-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .interior-detailing-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .interior-detailing-hero-container::before {
    content: "";
    background: url('http://localhost:3000/static/media/steam-clean.d11071c547b62a995e65.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .interior-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .interior-detailing-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .interior-detailing-hero-container > p {
    font-size: 20px;
  }
}

.gallery-hero-container {
  position: relative;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.gallery-hero-container::before {
  content: "";
  background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/black-car-ceramic-coating-application-2880w.jpg') center center/cover no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  opacity: 0.5;
}

.gallery-hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.gallery-hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .gallery-hero-container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .gallery-hero-container::before {
    content: "";
    background: url('https://lirp.cdn-website.com/1f28a86c/dms3rep/multi/opt/black-car-ceramic-coating-application-2880w.jpg') center center/cover no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    opacity: 0.5;
  }
}

@media screen and (max-width: 960px) {
  .gallery-hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .gallery-hero-container > h1 {
    font-size: 30px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
  }

  .gallery-hero-container > p {
    font-size: 20px;
  }
}

/* INTRO SECTION */

.intro-section {
  background-color: var(--black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 34px;
  display: flex;
}

.intro-container {
  max-width: 1500px;
  grid-column-gap: 4vw;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 93px;
  display: grid;
}

.snip1361 {
  font-family: 'SceneProRg';
  position: relative;
  overflow: hidden;
  margin: 10px;
  border-radius: 7px;
  max-height: 492.688px;
  height: 33vw;
  width: 100%;
  color: #141414;
  text-align: left;
  line-height: 1.4em;
  font-size: 16px;
}

.snip1361 * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.snip1361 img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  padding-right: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.snip1361 figcaption {
  position: absolute;
  top: calc(100%);
  padding: 15px 25px 65px;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 25px 17px 32px;
  display: flex;
  position: relative;
}

.snip1361 figcaption:before {
  position: absolute;
  content: '';
  z-index: 2;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to bottom, rgba(15, 16, 35, 0), #000);
}

.snip1361 h3,
.snip1361 p {
  margin: 0 0 10px;
}

.snip1361 h3 {
  letter-spacing: 3px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'SceneProRg';
  color: #FFF;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.1;
}

.snip1361 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.snip1361 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.snip1361:hover figcaption,
.snip1361.hover figcaption {
  top: 80px;
}

.tab-layout {
  display:flex;
  flex-wrap:wrap;
  margin-top: 30px;
}

.tab-layout > * {
  width:100%;
}

/* tablet breakpoint */

@media (min-width:768px) {
  .tab-layout > *:nth-child(1) {
    width:calc(100% / 3 * 2);
  }

  .tab-layout > *:nth-child(2) {
    width:calc(100% / 3);
    order:-1;
  }
}

.comparison-layout {
  display:flex;
  flex-wrap:wrap;
  /* margin-top: 30px; */
  align-items: center;
  gap: 50px;
}

.comparison-layout > * {
  width:100%;
}

/* comparison-la breakpoint */

@media (min-width:768px) {
  .comparison-layout > *:nth-child(1) {
    width:calc(100% / 3);
  }

  .comparison-layout > *:nth-child(2) {
    width:calc(100% / 6 * 4);
    max-width: 800px;
    order:-1;
  }
}

.video-hero-layout {
  display:flex;
  flex-wrap:wrap;
  /* margin-top: 30px; */
  align-items: center;
}

.video-hero-layout > * {
  width:100%;
}

/* video-hero-la breakpoint */

@media (min-width:768px) {
  .video-hero-layout > *:nth-child(1) {
    width:calc(100% / 3);
  }

  .video-hero-layout > *:nth-child(2) {
    width:calc(100% / 6 * 4);
    order:-1;
  }
}

.cc-diagram-key-panel {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: refrigerator-deluxe, sans-serif;
  font-size: 17px;
  display: flex;
}

@media (max-width:768px) {
  .cc-diagram-key-panel {
    justify-content: center;
    align-items: center;
  }
}

.cc-diagram-line {
  width: 30px;
  height: 2px;
  background-color: #fff;
  margin-right: 21px;
}

.cc-diagram-line.red {
  background-color: #e80200;
}

.videobgframe {
  object-position: 50% 38% !important;
  object-fit: cover !important;
}

video {
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:5;
  object-fit:cover;
  font-family:'object-fit: cover';
}

.service-deal-container {
  max-width: 1500px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 132px;
  padding-top: 57px;
  padding-bottom: 57px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 30px rgba(0, 0, 0, .46);
}

.service-deal-bg-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.service-deal-bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.service-deal-bg-overlay {
  background-color: rgba(0, 0, 0, .8);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.service-deal-text-container {
  z-index: 2;
  width: 90%;
  grid-column-gap: 8vw;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr .75fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: relative;
}

.service-deal-heading {
  color: #fff;
  margin-bottom: 0;
  font-size: 33px;
  line-height: 1.2;
}

.secondary-heading {
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-family: 'SceneProRg';
  font-size: 42px;
  line-height: 1;
}

.subheading {
  color: #e80200;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 600;
}

.subheading.light {
  color: #e80200;
  font-weight: 500;
}

.service-deal-paragraph {
  color: #fff;
  margin-bottom: 21px;
  display: block;
}

.service-deal-link-container {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.service-deal-paragraph {
  color: #fff;
  margin-bottom: 21px;
  display: block;
}

.w-dyn-hide, .w-dyn-bind-empty, .w-condition-invisible {
  display: none !important;
}

.service-deal-link {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  font-family: 'SceneProBold';
}

.nav-bar {
  z-index: 1000;
  width: 100%;
  height: 85px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, .38);
  border-bottom: 1px #c2c2c2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: fixed;
}

.nav-container {
  width: 90%;
  height: 100%;
  max-width: 1500px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-menu-area {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-link-container {
  width: 560px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 39px;
  display: flex;
  position: relative;
}

.nav-link {
  height: 100%;
  opacity: 1;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  transition: margin .7s cubic-bezier(.25, .46, .45, .94);
  display: flex;
}

.nav-link:hover {
  opacity: 1;
  margin-top: 0;
  text-decoration: none;
}

.nav-link.mobile-contact {
  display: none;
}

.button.nav-button {
  background-color: var(--red);
  color: var(--white);
  padding-left: 64px;
  padding-right: 64px;
}